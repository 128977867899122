import { Picture, Text, ButtonV2, useUI } from '@components/ui'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useProduct from '@shopify/product/use-product'
import useCoupons from '@lib/hooks/useCoupon'
import { normalizeStorefrontProduct } from '@components/normalize'
import Icon from '@components/icons'

import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useRouter } from 'next/router'
import { LoginPopTab } from '@components/ui/context'
import { pageGTMEvent } from '@lib/utils/thirdparty'

import HomeTitle from '@components/home/HomeTitle/HomeTitle'
import ProductCard from './ProductCard'
import { NextArrow, PrevArrow } from '../Arrow'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'
import cn from 'classnames'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode, Mousewheel } from 'swiper/modules'

const Index = ({
  data,
  id,
  pageHandle,
  shopData,
  metafields,
  profile,
  creditInfo,
  gtmRef,
  dealsProducts,
  mapFieldToCoupons,
}) => {
  const { setRelativeLink } = useRelativeLink()
  const [swiperRef, setSwiperRef] = useState(null)
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const [aniBoxH, setAniBoxH] = useState(0)
  const [playAni, setPlayAni] = useState(false)
  const [activeTab, setActiveTab] = useState(data?.tabsList?.[0]?.nav || '')
  const [resultProducts, setResultProducts] = useState(
    data?.products || data?.tabsList?.[0]?.products || []
  )
  const { locale } = useRouter()
  const { registrationsSettings } = metafields
  const [showGtm, setShowGtm] = useState(false)

  const {
    setLoginPop,
    setLoginDefaultTab,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
  } = useUI()

  useEffect(() => {
    if (data?.products?.length > 0) return
    const products = data?.tabsList?.find(
      (tab) => tab?.nav === activeTab
    )?.products
    if (products) {
      setResultProducts(products)
    }
  }, [activeTab])

  const handles = resultProducts?.map((item) => item.handle) || []
  const skus = resultProducts?.map((item) => item.sku) || []
  const { data: result } = useProduct({
    handles: handles?.join(','),
  })
  // const { coupons } = useCoupons(skus)
  const [coupons, setCoupons] = useState({})
  useEffect(() => {
    if (skus?.length && dealsProducts?.length)
      setCoupons(mapFieldToCoupons(dealsProducts, skus, 'sku'))
  }, [skus?.length, dealsProducts?.length])

  const changeItem = (nav) => {
    setActiveTab(nav)
    swiperRef?.slideTo(0)
  }

  useEffect(() => {
    if (!data?.animation?.trigger) return
    const height = window.screen.height
    const domH = document?.querySelector(`#${id} .aniBox`)?.offsetHeight
    setAniBoxH(domH + height)
    if (!data?.animation) return

    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.create({
      trigger: `#${data?.animation?.trigger}`,
      start: 'bottom top+=60px',
      end: '+=0',
      // markers: true,
      // scrub: 0,
      onEnter: () => {
        setShowGtm(true)
        gsap.to(`#${id} .aniOpactiy`, { opacity: 1, duration: '0.3' })
        setPlayAni(true)
      },
      onEnterBack: () => {
        gsap.to(`#${id} .aniOpactiy`, { opacity: 0, duration: '0.3' })
        setPlayAni(false)
      },
    })
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${data?.animation?.trigger}`,
          start: 'bottom bottom',
          end: `bottom top`,
          // markers: true,
          scrub: 0,
        },
      })
      .to(`#${id} .aniBg`, {
        transform: 'translateY(0)',
        duration: '1',
        ease: 'linear',
      })
  }, [])

  const handleButtonClick = useCallback(
    (buttonConfig) => {
      if (profile) return
      if (registrationsSettings) {
        setRegistrationsPopup(true)
        setRegistrationsModelStatus(
          buttonConfig?.type === 'signIn' ? 'quickLogin' : 'main'
        )
      } else {
        if (buttonConfig.type === 'login') {
          setLoginDefaultTab(LoginPopTab.Login)
        } else {
          setLoginDefaultTab(LoginPopTab.Register)
        }
        setLoginPop(true)
      }

      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'entry_for_register',
        event_parameters: {
          page_group: '',
          button_name: buttonConfig?.label || '',
          position: data?.gtmTitle || '',
        },
      })
    },
    [locale, setRegistrationsPopup, setRegistrationsModelStatus]
  )

  const textInfo = useMemo(() => {
    let infoKey = 'unLogin'
    let title
    if (profile) {
      infoKey = 'login'

      const displayName = profile.first_name
        ? profile.first_name + ' ' + profile.last_name
        : profile.email

      title = data[infoKey]?.title?.replace('$name', displayName) || ''
    } else {
      title = data[infoKey]?.title || ''
    }

    return {
      title,
      subtitle: data[infoKey]?.subtitle || '',
      desc: data[infoKey]?.desc || '',
      buttons: data[infoKey]?.buttons || [],
      loginButtonTxt: data[infoKey]?.prodButton || '',
    }
  }, [profile, data, creditInfo])

  const showProductGtm = () => {
    let currency
    const items = resultProducts?.map((item, index) => {
      const proData = {
        ...shopData?.pageCommon,
        ...shopData?.shopCommon,
        ...data,
      }
      const product = normalizeStorefrontProduct(item, result, coupons, proData)
      currency = product?.currencyCode || ''
      return {
        item_id: product?.sku || '',
        item_name: product?.title || '',
        item_brand: product?.brand || '',
        item_category: product?.category || '',
        item_variant: product?.variantName || '',
        price: product?.price || '',
        index: index,
      }
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list',
      event_parameters: {
        page_group: 'Home Page',
        currency: currency,
        item_list_name: gtmRef || data?.gtmTitle,
        items: items,
      },
    })
  }

  useEffect(() => {
    if (!showGtm || !result) return
    showProductGtm()
  }, [result, showGtm])

  const numberFormat = (num) => {
    if (!num) return 0
    if (typeof Intl === 'undefined') {
      return num
    }
    return new Intl.NumberFormat().format(num)
  }

  return (
    <>
      <style>{`
        #${data?.animation?.trigger} {
          z-index: 1;
        }
        #${id} .aniOpactiy {
          opacity: ${data?.animation?.trigger ? 0 : 1};
        }
        #${id} .aniTrigger {
          margin-top: ${data?.animation?.trigger ? '-100vh' : '0'};
        }
        #${id} .aniBg {
          transform: ${
            data?.animation?.trigger ? 'translateY(40vh)' : 'translateY(0)'
          };
        }
      `}</style>
      <div
        className={cn('aniTrigger relative')}
        style={{
          background: data?.styles?.backgroundColor || 'transparent',
          height: data?.animation?.trigger ? `${aniBoxH}px` : 'auto',
        }}
      >
        <div
          className={cn(
            'aniBox sticky top-0 w-full overflow-hidden',
            data?.styles?.paddingTop && s.paddingTop,
            data?.styles?.paddingBottom && s.paddingBottom
          )}
        >
          <div className="layer">
            <div className="content relative z-[1] pt-[100px] md:pt-0">
              <div className="w-full pl-[32px] xl:pl-0">
                <HomeTitle
                  data={textInfo}
                  pageHandle={pageHandle}
                  theme={data?.theme}
                  hideLink={true}
                  playAni={data?.animation?.trigger ? playAni : true}
                  hasAni={data?.animation ? data?.animation?.hasAni : true}
                  cns={{
                    txt: 'md:text-center',
                  }}
                ></HomeTitle>

                {profile && (
                  <div className="aniOpactiy mb-[24px] flex items-center gap-[48px] md:justify-center">
                    <div className="">
                      <div className="mb-[4px] text-[40px] font-[700] text-[#fff] md:text-[30px]">
                        {creditInfo
                          ? numberFormat(Number(creditInfo?.available_credit))
                          : 0}
                      </div>
                      <div className="text-[20px] font-[500] leading-[1.4] text-[#999] md:text-[14px]">
                        {data?.ankerCredits}
                      </div>
                    </div>
                    <div className="h-[64px] w-[1px] bg-[#fff] bg-opacity-20 md:h-[30px]"></div>
                    <div className="">
                      <div className="mb-[4px] text-[40px] font-[700] text-[#999] md:text-[30px]">
                        {creditInfo
                          ? numberFormat(Number(creditInfo?.pending_credit))
                          : 0}
                      </div>
                      <div className="text-[20px] font-[500] leading-[1.4] text-[#999] md:text-[14px]">
                        {data?.coomingSoon}
                      </div>
                    </div>
                  </div>
                )}

                {/* buttons */}
                {textInfo?.buttons && (
                  <div className="aniOpactiy mb-[160px] flex gap-[12px] md:justify-center md:!opacity-100">
                    {textInfo?.buttons?.map((item, index) => (
                      <ButtonV2
                        key={id + index + item?.theme}
                        variant={item?.theme}
                      >
                        <a
                          href={
                            !item?.link
                              ? null
                              : appendRefParameter({
                                  url: setRelativeLink({ link: item?.link }),
                                  refValue: gtmRef || data?.ref || pageHandle,
                                })
                          }
                          onClick={() => !item?.link && handleButtonClick(item)}
                          dangerouslySetInnerHTML={{ __html: item?.label }}
                        />
                      </ButtonV2>
                    ))}
                  </div>
                )}
              </div>

              {/* nav */}
              {(data?.tabsList || data?.boxLinkUrl) && (
                <div className="aniOpactiy mb-[48px]">
                  <div className="flex justify-between">
                    <div className="flex gap-[32px]">
                      {data?.tabsList?.map((item, index) => (
                        <div
                          key={id + '-nav-' + index}
                          className="relative cursor-pointer pb-[12px]"
                          onClick={() => changeItem(item?.nav)}
                        >
                          <Text
                            className="text-[20px] font-[600] leading-[1.2] text-[#1D1D1F]"
                            html={item?.nav}
                          />
                          <div
                            className={cn(
                              'absolute bottom-[-1px] left-0 h-[2px] bg-[#00BEFA] transition-all duration-[0.4s]',
                              activeTab === item?.nav ? 'w-full' : 'w-0'
                            )}
                          />
                        </div>
                      ))}
                    </div>
                    {data?.boxLinkUrl && (
                      <a
                        className={cn(
                          'flex items-center justify-center text-[20px] font-[600] leading-[1.4] transition-all duration-[0.4s] md:hidden xl:text-[16px]',
                          data?.theme === 'dark'
                            ? 'text-[#999] [&:hover]:text-[#ddd] [&:hover_path]:stroke-[#ddd]'
                            : 'text-[#777] [&:hover]:text-[#1D1D1F] [&:hover_path]:stroke-[#1D1D1F]'
                        )}
                        href={appendRefParameter({
                          url: setRelativeLink({ link: data?.boxLinkUrl }),
                          refValue: gtmRef || data?.ref || pageHandle,
                        })}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }}
                        />
                        <Icon
                          iconKey="arrowRight"
                          width={24}
                          height={24}
                        ></Icon>
                      </a>
                    )}
                  </div>
                  {data?.tabsList && (
                    <div className="h-[1px] w-full bg-[#1D1D1F] bg-opacity-20"></div>
                  )}
                </div>
              )}

              {/* product list */}
              <div
                className={cn(
                  s.swiperProductBox,
                  'aniOpactiy',
                  data?.isMember && 'md:h-[370px]'
                )}
              >
                <div className={cn('relative max-w-[1280px]', s.listBox)}>
                  <Swiper
                    className="!overflow-visible"
                    navigation={{
                      nextEl: `.${id}-custom-swiper-button-next`,
                      prevEl: `.${id}-custom-swiper-button-prev`,
                    }}
                    onSwiper={setSwiperRef}
                    onProgress={(swiper) => {
                      setStart(swiper.isBeginning)
                      setEnd(swiper.isEnd)
                    }}
                    modules={[Navigation, FreeMode, Mousewheel]}
                    mousewheel={{
                      forceToAxis: true,
                    }}
                    freeMode={true}
                    slidesPerGroup={2}
                    breakpoints={{
                      0: {
                        spaceBetween: '12px',
                        slidesPerView: 1.32,
                      },
                      768: {
                        spaceBetween: '12px',
                        slidesPerView: 2.65,
                      },
                      1023: {
                        spaceBetween: '16px',
                        slidesPerView: 3,
                        freeMode: false,
                      },
                    }}
                  >
                    {resultProducts?.map((item, index) => {
                      const proData = {
                        ...shopData?.pageCommon,
                        ...shopData?.shopCommon,
                        ...data,
                      }
                      const product = normalizeStorefrontProduct(
                        item,
                        result,
                        coupons,
                        proData
                      )
                      if (!product) return null
                      return (
                        <SwiperSlide
                          key={id + 'SwiperSlide' + index}
                          className="!flex !h-[unset]"
                        >
                          <ProductCard
                            data={proData}
                            pageHandle={pageHandle}
                            product={product}
                            cns={s}
                            defaultRef={
                              gtmRef ? gtmRef + '_' + (index + 1) : pageHandle
                            }
                            isDark={data?.theme === 'dark'}
                            gmtIndex={index + 1}
                            itemListName={gtmRef || data?.gtmTitle}
                            isMember={data?.isMember}
                            registrationsSettings={registrationsSettings}
                            isLogin={profile}
                            loginButtonTxt={textInfo?.loginButtonTxt}
                          ></ProductCard>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  <PrevArrow
                    isDisabled={isStart}
                    className={cn(
                      `${id}-custom-swiper-button-prev`,
                      s.arrow,
                      'absolute left-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                    )}
                    isDark={true}
                  ></PrevArrow>
                  <NextArrow
                    isDisabled={isEnd}
                    className={cn(
                      `${id}-custom-swiper-button-next`,
                      s.arrow,
                      'absolute right-[32px] top-1/2 z-[1] -translate-y-1/2 md:hidden'
                    )}
                    isDark={true}
                  ></NextArrow>
                </div>
              </div>

              {data?.boxLinkUrl && (
                <a
                  className={cn(
                    'aniOpactiy mt-[24px] hidden items-center justify-center text-[16px] font-[600] leading-[1.4] transition-all duration-[0.4s] md:flex xl:text-[16px]',
                    data?.theme === 'dark'
                      ? 'text-[#999] [&:hover]:text-[#ddd] [&:hover_path]:stroke-[#ddd]'
                      : 'text-[#777] [&:hover]:text-[#1D1D1F] [&:hover_path]:stroke-[#1D1D1F]'
                  )}
                  href={appendRefParameter({
                    url: setRelativeLink({ link: data?.boxLinkUrl }),
                    refValue: gtmRef || data?.ref || pageHandle,
                  })}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: data?.boxLinkTxt }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      className="transition-all duration-[0.4s]"
                      d="M10.5 8L14.5 12L10.5 16"
                      stroke="#777"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              )}
            </div>
          </div>
          {data?.bgImg && (
            <div className="aniBg absolute left-0 top-0 w-full">
              <Picture
                source={`${data?.bgImg}, ${data?.bgImgMob || data?.bgImg} 768`}
                className="w-full"
              ></Picture>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Index
